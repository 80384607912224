section {
    position: relative;
}

button.empty-section {
    margin: 5px;
}

button.empty-section:hover {
    background-color: inherit;
    color: #343f52;
}

button.editor-dot-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0%;
    background-color: inherit;
    border: none;
}

.editor-dot-menu.right {
    left: auto;
    right: 0;
}

.editor-dot-menu div {
    margin: 2px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #686868;
}

section:hover .editor-dot-menu {
    display: block;
    opacity: 100%;
    transition: ease-in opacity .5s;
}

.context-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    display: flex;
    flex-direction: column;
}

.context-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.context-menu li {
    border-bottom: 1px solid #eee;
    padding: 10px;
}

.context-menu li:hover {
    background-color: #eee;
    cursor: pointer;
}


.edit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.edit-overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.edit-overlay-content {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.edit-overlay-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}


.other-editor-btn {
    background: blue;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}
